<template>
  <div>
    <CRow>
      <!-- 좌측 메뉴 -->
      <CCol col="4" class="py-2">
        <!-- 프로필 사진 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
          <CRow>
            <CCol col="3" class="py-2" style="display: flex; align-items: center; justify-content: center;">
              <img :src="require('@/assets/images/storeManagement_inventory/dummy.webp')" style="width: 100%;" />
            </CCol>
            <CCol col="5" class="py-2">
              <CCol col="12" class="py-2">
                <span style="color: #D7E400">LV.0</span>
                디엔에스
              </CCol>
              <CCol col="12" class="py-2">
                <img :src="require('@/assets/images/storeManagement_inventory/green.png')" style="height: 100%;" />
                <span style="font-weight: bold;">영업중</span>
              </CCol>
            </CCol>
            <CCol col="4" class="py-2" style="display: flex; align-items: center; justify-content: flex-end;">
              <CCol col="12" class="py-2">
                미니샵 방문 : 10
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 영업상태 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              영업상태 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_workState_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 영업/출장 시간 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              영업/출장 시간 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_workTimeSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 수리의뢰 옵션 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              수리의뢰 옵션 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_workOptionSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 매장소개 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              매장소개 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_companyInfoSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 할인 설정 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              할인 설정
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_discountSetting_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 보유재고 변경 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              보유재고 변경
            </CCol>
            <CCol col="4" class="py-2">
              <CLink style="text-decoration-line: none; color: black;"
                to="/c_storeManagement_inventory"
              >
                <CCol col="12" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                  설정하기
                </CCol>
              </CLink>
            </CCol>
          </CRow>
        </CCol>
        <!-- 사업자정보 변경/등록 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              사업자정보 변경/등록
            </CCol>
            <CCol col="4" class="py-2">
              <CCol col="12" class="py-2" @click="storeManagement_companyRegister_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                설정하기
              </CCol>
            </CCol>
          </CRow>
        </CCol>
        <!-- 정산 관리 -->
        <CCol col="12" class="mb-3 py-2" style="border: 1px #707070 solid;">
          <CRow>
            <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 17px; font-weight: bold;">
              정산 관리
            </CCol>
            <CCol col="4" class="py-2">
              <CLink style="text-decoration-line: none; color: black;"
                to="/c_storeManagement_calculate"
              >
                <CCol col="12" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
                  설정하기
                </CCol>
              </CLink>
            </CCol>
          </CRow>
        </CCol>
      </CCol>
      <!-- 우측 메인 -->
      <CCol col="6" class="py-2">
        <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; font-size: 18px; font-weight: bold;">
          정산내역
        </CCol>
        <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; font-size: 16px; font-weight: bold;">
          <CRow>
            <Datepicker v-model="calculate_start"></Datepicker>
            &nbsp;~&nbsp;
            <Datepicker v-model="calculate_end"></Datepicker>
          </CRow>
        </CCol>
        <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; font-size: 18px; font-weight: bold;">
          입금 예정 금액 : 16,940 원
        </CCol>
        <CRow style="border: 1px #707070 solid;">
          <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold;">
            입금일
          </CCol>
          <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            <span style="border: 1px red solid; color: red;">입금예정</span>&nbsp;
            <span style="background-color: #19C1FF; color: #FFFFFF;">입금완료</span>&nbsp;
            2021.07.05
          </CCol>
          <CCol col="3" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            정산기간
          </CCol>
          <CCol col="4" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            2021.07.01
          </CCol>
          <CCol col="1" class="py-2" style="display: flex; align-items: center; justify-content: center; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            &nbsp;~&nbsp;
          </CCol>
          <CCol col="4" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            2021.07.01
          </CCol>
          <CCol col="3" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            이용 서비스
          </CCol>
          <CCol col="9" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            수리요 수리중개
          </CCol>
          <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold;">
            (A) 거래금액
          </CCol>
          <CCol col="5" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            (A-1) 수리의뢰 금액
          </CCol>
          <CCol col="7" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            20,000
          </CCol>
          <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold;">
            (B) 할인제공비용
          </CCol>
          <CCol col="5" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            (B-1) 사장님 발급 쿠폰 할인
          </CCol>
          <CCol col="7" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            -2,000
          </CCol>
          <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold;">
            (C) 차감금액
          </CCol>
          <CCol col="5" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold;">
            (C-1) 서비스 수수료(3.3%)
          </CCol>
          <CCol col="7" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold;">
            -660
          </CCol>
          <CCol col="5" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            (C-2) 카드수수료 및 결제망 이용료
          </CCol>
          <CCol col="7" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            -400
          </CCol>
          <CCol col="5" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            (D) 직접결제금액
          </CCol>
          <CCol col="7" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            0
          </CCol>
          <CCol col="5" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            (E) 조정 금액
          </CCol>
          <CCol col="7" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold; border-bottom: 1px #707070 solid;">
            0
          </CCol>
          <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold;">
            입금받을 금액
          </CCol>
          <CCol col="5" class="py-2" style="display: flex; align-items: center; justify-content: flex-start; font-size: 18px; font-weight: bold;">
            (A+B+C+E)
          </CCol>
          <CCol col="7" class="py-2" style="display: flex; align-items: center; justify-content: flex-end; font-size: 18px; font-weight: bold;">
            16,940
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <!-- 재고상품 추가 -->
    <CModal
      title="재고상품 추가"
      :show.sync="inventory_product_add_modal"
      size="xl"
    >
      <div class="px-2">
        <!-- 재고명 -->
        <CRow class="mb-2">
          <CCol col="12" style="font-size: 18px; font-weight: bold;">재고명</CCol>
        </CRow>
        <CRow class="mb-4">
          <CCol col="10" style="font-size: 18px;">
            <input v-model="inventory_product_add_title" placeholder="재고명을 입력하세요" style="width: 100%;">
          </CCol>
          <CCol col="2">
            <CCol col="12" class="py-2" @click="inventory_product_search_modal = true" style="background-color: #19C1FF; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
              검색
            </CCol>
          </CCol>
        </CRow>
        <!-- 분류 / 가격 -->
        <CRow>
          <CCol col="5">
            <CRow class="mb-2">
              <CCol col="12" style="font-size: 18px; font-weight: bold;">분류</CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol col="12" style="font-size: 18px;">
                <vSelect :options="['CPU', '메인보드', '메모리', '그래픽카드', 'SSD/HDD', '파워', '케이스', '기타']"></vSelect>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="7">
            <CRow class="mb-2">
              <CCol col="12" style="font-size: 18px; font-weight: bold;">가격</CCol>
            </CRow>
            <CRow class="mb-4">
              <CCol col="12" style="font-size: 18px;">
                <input v-model="inventory_product_add_price" placeholder="금액을 입력하세요" style="width: 90%;">&nbsp;원
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <!-- 소개 -->
        <CRow class="mb-2">
          <CCol col="12" style="font-size: 18px; font-weight: bold;">소개</CCol>
        </CRow>
        <CRow class="mb-4">
          <CCol col="12" style="font-size: 18px;">
            <input v-model="inventory_product_add_infomation" placeholder="소개를 입력하세요" style="width: 100%;">
          </CCol>
        </CRow>
        <!-- 수리의뢰 옵션 연결 -->
        <CRow class="mb-2">
          <CCol col="8" style="font-size: 18px; font-weight: bold;">수리의뢰 옵션 연결</CCol>
          <CCol col="4">
            <CRow>
              <CCol col="4" class="py-2">
                <CCol col="12" style="background-color: #19C1FF; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
                  등록
                </CCol>
              </CCol>
              <CCol col="4" class="py-2">
                <CCol col="12" style="border: 1px red solid; color: red; display: flex; align-items: center; justify-content: center;">
                  해제
                </CCol>
              </CCol>
              <CCol col="4" class="py-2">
                <CCol col="12" style="border: 1px #19C1FF solid; color: #19C1FF; display: flex; align-items: center; justify-content: center;">
                  필수
                </CCol>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="mb-4 mx-2" style="border: 2px #ECECEC solid; align-items: center;">
          <CCol col="5">
            <CCol col="12" style="font-size: 18px;">
              <input v-model="inventory_product_add_option_title" placeholder="옵션명을 입력하세요" style="width: 100%;">
            </CCol>
          </CCol>
          <CCol col="4">
            <CCol col="12" style="font-size: 18px;">
              <input v-model="inventory_product_add_option_price" placeholder="금액을 입력하세요" style="width: 80%;">&nbsp;원
            </CCol>
          </CCol>
          <CCol col="3">
            <CRow>
              <CCol col="6" class="py-2">
                <CCol col="12" style="border: 1px red solid; color: red; display: flex; align-items: center; justify-content: center;">
                  해제
                </CCol>
              </CCol>
              <CCol col="6" class="py-2">
                <CCol col="12" style="border: 1px #19C1FF solid; color: #19C1FF; display: flex; align-items: center; justify-content: center;">
                  필수
                </CCol>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <!-- 취소 / 추가 -->
        <CRow style="justify-content: center;">
          <CCol col="2" class="py-2">
            <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
              취소
            </CCol>
          </CCol>
          <CCol col="2" class="py-2">
            <CCol col="12" class="py-2" style="background-color: #19C1FF; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
              추가
            </CCol>
          </CCol>
        </CRow>
      </div>
    </CModal>

    <CModal
      :show.sync="inventory_product_search_modal"
      size="xl"
    >
      <div>
        <!-- 검색창 -->
        <CRow style="border-bottom: 1px #707070 solid;">
          <CCol col="10" class="py-2" style="display: flex; align-items: center; justify-content: center; font-size: 22px; font-weight: bold;">
            <input v-model="inventory_product_search_title" placeholder="상품명을 입력하세요" style="width: 100%; border: hidden;">
          </CCol>
          <CCol col="2" class="py-2" style="display: flex; align-items: center; justify-content: center;">
            <img :src="require('@/assets/images/storeManagement_inventory/search_black.png')" style="width: 50%;" />
          </CCol>
        </CRow>
        <!-- 검색전 -->
        <CRow class="px-5 py-4">
          <CCol col="12" class="py-1" style="font-size: 22px; font-weight: bold;">
            tip
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px;">
            아래와 같은 조합으로 검색을 하시면 더욱 정확한 결과가 검색됩니다.
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            제조사 + 용량
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) 삼성 8GB, 마이크로닉스 500W, WD 256GB
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            속도 + 용량
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) 25600 16GB, 21300 8GB
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            제조사 + 칩셋
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) MSI A520, 기가바이트 B560
          </CCol>
          <CCol col="12" class="py-1" style="font-size: 18px;">
            인터페이스 + 용량
          </CCol>
          <CCol col="12" class="pb-2" style="font-size: 18px; color: #19C1FF;">
            예) SATA 256GB, NVME 1TB, DDR4 16GB
          </CCol>
        </CRow>
        <!-- 검색후 -->
        <CRow class="mx-2 my-2 px-3 py-2" style="border: 1px #F5F5F5 solid;">
          <CCol col="12" class="py-2">
            <CRow>
              <CCol col="3" style="font-size: 18px;">
                <vSelect :options="['CPU', '메인보드', '메모리', '그래픽카드', 'SSD/HDD', '파워', '케이스', '기타']"></vSelect>
              </CCol>
              <CCol col="3" style="font-size: 18px;">
                <vSelect :options="['인텔', 'AMD']"></vSelect>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" class="py-2">
            <CRow>
              <CCol col="2">
                <img :src="require('@/assets/images/storeManagement_inventory/product.png')" style="width: 100%;" />
              </CCol>
              <CCol col="8" style="font-size: 14px;">
                <span style="font-size: 15px; font-weight: bold;">AMD 라이젠5-3세대 3600 (마티스) (멀티팩)</span><br>
                AMD(소켓 AM4) / 3세대 (Zen 2) / 7nm / 6코어 / 12쓰레드 / 기본 클럭 3.6GHz
              </CCol>
              <CCol col="2" style="display: flex; align-items: center; justify-content: center;">
                <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
                  선택
                </CCol>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" class="py-2">
            <CRow>
              <CCol col="2">
                <img :src="require('@/assets/images/storeManagement_inventory/product.png')" style="width: 100%;" />
              </CCol>
              <CCol col="8" style="font-size: 14px;">
                <span style="font-size: 15px; font-weight: bold;">AMD 라이젠5-3세대 3600 (마티스) (멀티팩)</span><br>
                AMD(소켓 AM4) / 3세대 (Zen 2) / 7nm / 6코어 / 12쓰레드 / 기본 클럭 3.6GHz
              </CCol>
              <CCol col="2" style="display: flex; align-items: center; justify-content: center;">
                <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
                  선택
                </CCol>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" class="py-2">
            <CRow>
              <CCol col="2">
                <img :src="require('@/assets/images/storeManagement_inventory/product.png')" style="width: 100%;" />
              </CCol>
              <CCol col="8" style="font-size: 14px;">
                <span style="font-size: 15px; font-weight: bold;">AMD 라이젠5-3세대 3600 (마티스) (멀티팩)</span><br>
                AMD(소켓 AM4) / 3세대 (Zen 2) / 7nm / 6코어 / 12쓰레드 / 기본 클럭 3.6GHz
              </CCol>
              <CCol col="2" style="display: flex; align-items: center; justify-content: center;">
                <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">
                  선택
                </CCol>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <!-- 검색 안내 -->
        <CRow style="border-top: 1px #707070 solid;">
          <CCol col="12" class="px-5 py-3" style="font-size: 15px; font-weight: bold;">
            자료 : 다나와 PC견적 제공<br>
            다나와 PC견적에 등록되어 있지 않은 상품은 수기로 등록해야 합니다.<br>
            단, 수기로 등록한 상품은 수리매칭이 이루어지지 않을수 있습니다.
          </CCol>
        </CRow>
      </div>
    </CModal>

    <!-- 영업상태 모달 -->
    <CModal
      title="영업상태 설정"
      :show.sync="storeManagement_workState_modal"
      size="xl"
    >
      <StoreManagementWorkState></StoreManagementWorkState>
    </CModal>

    <!-- 영업시간 모달 -->
    <CModal
      title="영업시간 설정"
      :show.sync="storeManagement_workTimeSetting_modal"
      size="xl"
    >
      <StoreManagementWorkTimeSetting></StoreManagementWorkTimeSetting>
    </CModal>

    <!-- 수리의뢰옵션 모달 -->
    <CModal
      title="수리의뢰옵션 설정"
      :show.sync="storeManagement_workOptionSetting_modal"
      size="xl"
    >
      <StoreManagementWorkOptionSetting></StoreManagementWorkOptionSetting>
    </CModal>

    <!-- 매장소개 모달 -->
    <CModal
      title="매장소개 설정"
      :show.sync="storeManagement_companyInfoSetting_modal"
      size="xl"
    >
      <StoreManagementCompanyInfoSetting></StoreManagementCompanyInfoSetting>
    </CModal>

    <!-- 할인 모달 -->
    <CModal
      title="할인 설정"
      :show.sync="storeManagement_discountSetting_modal"
      size="xl"
    >
      <StoreManagementDiscountSetting></StoreManagementDiscountSetting>
    </CModal>

    <!-- 사업자정보 등록/변경 모달 -->
    <CModal
      title="사업자정보 등록/변경"
      :show.sync="storeManagement_companyRegister_modal"
      size="xl"
    >
      <StoreManagementCompanyRegister></StoreManagementCompanyRegister>
    </CModal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Datepicker from 'vuejs-datepicker';
import StoreManagementWorkState from './storeManagement_workState';
import StoreManagementWorkTimeSetting from './storeManagement_workTimeSetting';
import StoreManagementWorkOptionSetting from './storeManagement_workOptionSetting';
import StoreManagementCompanyInfoSetting from './storeManagement_companyInfoSetting';
import StoreManagementDiscountSetting from './storeManagement_discountSetting';
import StoreManagementCompanyRegister from './storeManagement_companyRegister';

export default {
  name: 'c_storeManagement_calculate',
  components: {
    vSelect,
    Datepicker,
    StoreManagementWorkState,
    StoreManagementWorkTimeSetting,
    StoreManagementWorkOptionSetting,
    StoreManagementCompanyInfoSetting,
    StoreManagementDiscountSetting,
    StoreManagementCompanyRegister
  },
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      inventory_product_add_modal: false,
      inventory_product_search_modal: false,
      // 재고상품 추가
      inventory_product_add_title: '',
      inventory_product_add_price: 0,
      inventory_product_add_infomation: '',
      inventory_product_add_option_title: '',
      inventory_product_add_option_price: 0,
      // 재고상품 검색
      inventory_product_search_title: '',
      // 정산조회 시작일
      calculate_start: '',
      // 정산조회 종료일
      calculate_end: '',
      // 영업상태 모달
      storeManagement_workState_modal: false,
      // 영업시간 모달
      storeManagement_workTimeSetting_modal: false,
      // 수리의뢰옵션 모달
      storeManagement_workOptionSetting_modal: false,
      // 매장소개 모달
      storeManagement_companyInfoSetting_modal: false,
      // 할인 모달
      storeManagement_discountSetting_modal: false,
      // 사업자정보 등록/변경 모달
      storeManagement_companyRegister_modal: false,
    }
  }
}
</script>
